html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.hcenter
{
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.clickable:hover
{
  cursor: pointer;
}

.clickable-bg:hover
{
  cursor: pointer;
  filter: brightness(.7);
  transition: .25s;
  /* background-color: white; */
}

.no-overflow
{
  overflow: hidden;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: calc(100vw - 65px);
}

@font-face {
  font-family: 'Montserrat';
  src: url(../../public/fonts/Montserrat-Black.woff2) format('woff2'),
      url(../../public/fonts/Montserrat-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KDA';
  src: url(../../public/fonts/KDA-Regular.woff2) format('woff2'),
      url(../../public/fonts/KDA-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.inline-flex {
  display: inline-flex;
}

.hide-radio .MuiRadio-root {
  display: none !important;
}