.waiting-box {
  --main-bg: conic-gradient(
                  from var(--border-angle),
                  #213,
                  #112 5%,
                  #112 60%,
                  #213 95%
  );
  background: var(--main-bg);
}

.outlined-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5px;

  place-content: center;
  overflow: hidden;
  color: white;
  text-shadow: 0 1px 0 #000;

  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(
                  from var(--border-angle),
                  #213,
                  #112 5%,
                  #112 60%,
                  #213 95%
  );

  border: solid 5px transparent;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);

  background:
          // padding-box clip this background in to the overall element except the border.
          var(--main-bg) padding-box,
            // border-box extends this background to the border space
          var(--gradient-border) border-box,
            // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
          var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.draft-disabled {
  filter: grayscale(1);
}

.expandable:hover {
  width: 100%;
}